@import url('../../global.css');

.icon-container {
    margin: 2rem auto ;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    border-radius: 100%;
    background-color: var(--primary);
}
@media only screen and (max-width: 600px) {}