@import url('../../global.css');

.nav-link {
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 600;
}

.navbar {
    background-color: #0C0C0C;
    z-index: 100;
}

.active a {
    color: #FF0000 !important;
}

.logo {
    margin-left: 30px;
    width: 76px;
}

.icon {
    font-size: 1.3rem;
}

.navbar .navbar-toggler {
    border-color: none !important;
    border: none !important;
}

.navbar .navbar-toggler-icon{
    background-image: url('../../assets/imgs/icon_navbar.svg') !important;
}

@media only screen and (max-width: 600px) {
    .nav-link {
      text-align: left;
      padding-left: 0;
      padding-right: 0;
    }

    .text-mb {
        margin-left: 16px;
        text-transform: uppercase;
    }

    .navbar {
        height: 100%;
    }

    .nav-link.active {
        text-decoration: underline;
        text-decoration-color: #FF0000;
        text-decoration-thickness: 1px;
        text-underline-offset: 6px;
    }

    .icon {
        margin-left: 16px;
        font-size: 1.8rem;
    }
}