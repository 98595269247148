.chart {
    font-size: 1.5rem;
}

.text-chart {
    font-size: 0.8rem;
}

.cont-chart {
    background-color: #0C0C0C;
    border-radius: 15px 0px;
    width: 15%;
}

.cont-chart-full {
    background-color: #0C0C0C;
    width: 100%;
}

.porcents-nav {
    position: fixed;
    top: 7%;
    width: 32%;
    z-index: 999;
    background-color: #0C0C0C;
    border-bottom-right-radius: 24px;
}

@media (max-height: 660px) {
    .porcents-nav {
        top: 11%;
        width: 40% !important;
    }
}