@import url('../../global.css');

/* BACKGROUND */

.container-blog {
    background: url('../../assets/imgs/bg_blog.png') no-repeat;
    background-size: cover;
}

/* BACKGROUND */

.text-color {
    color: #FF6060;
    font-weight: 700;
    font-size: 18px;
}

.text-size {
    font-size: 24px;
}

/* SWIPER */

.card-round {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background-color: #2a2a2a;
    border: 10px solid #0000004a;
    position: relative;
    padding: 10px;
    opacity: 0.5;
    cursor: pointer;
    margin: 0 auto;
}

.active-card {
    opacity: 1;
    transition: 1s;
}

.icon-swiper-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
}

.title-swiper {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 16px;
    width: 100%;
}

/* SWIPER */
.date {
    color: white;
    font-weight: 300;
    font-size: 18px;
}

.container-blog-images {
    position: relative;
}

.hashtag {
    color: white;
    font-weight: 300;
    margin-right: 16px;
    font-style: italic;
}

.image-blog {
    border-radius: 25px;
    object-fit: cover;
    width: 100%;
    height: 350px;
}

.col-index {
    z-index: 999;
}

.h-active {
    color: #FF6060;
    font-weight: 500;
}

.card-blur-blog {
    position: absolute;
    top: -20px;
    left: -50px;
    height: 300px;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    background: linear-gradient(222deg, rgba(255, 255, 255, 0.3) 0%, rgba(0, 0, 0, 0.2) 100%);
    border-radius: 25px;
    width: 100%;
}

.card-blog {
    height: 445px;
    background: transparent !important;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.card-blog-first {
    border-radius: 25px;
    width: 100%;
    background: linear-gradient(222deg, rgba(255, 255, 255, 0.3) 0%, rgba(0, 0, 0, 0.2) 100%);
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.title-card-blog {
    width: 95%;
}

.header-img-card-mb {
    height: 270px !important;
    border-radius: 16px 16px 0 0 !important;
}

.header-img-card {
    height: 270px;
    object-fit: cover;
    border-radius: 16px 16px 0 0 !important;
}


@media only screen and (max-width: 600px) {
    .container-blog {
        background: none !important;
    }
}