@import url('../../global.css');
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");

/* BACKGROUND */

.container-faqs {
    background: url('../../assets/imgs/bg_faqs.png') no-repeat;
    background-size: cover;
}

/* BACKGROUND */

.list-faqs li {
    list-style: square;
}

.list-faqs {
    margin-left: 50px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.text-color {
    color: #FF6060;
    font-weight: 700;
    font-size: 18px;
}

.text-size {
    font-size: 24px;
}

.searchbar {
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.2);
    color: #FFFFFF;
    border: none;
    margin-left: 0 !important;
}

.input-search {
    background: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 15px;
}

.searchbar>.form-control, .searchbar>.form-select {
    background: rgba(255, 255, 255, 0.2);
    color: #FFFFFF;
}

.searchbar:focus {
    border: none !important;
}

/* SWIPER */

.card-round-faq {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background-color: #2a2a2a;
    border: 10px solid #0000004a;
    position: relative;
    padding: 10px;
    opacity: 0.5;
    cursor: pointer;
    margin: 0 auto;
}

.active-card {
    opacity: 1;
    transition: 1s;
}

.icon-swiper-container-faq {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
}

.title-swiper {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 16px;
    width: 100%;
}

/* SWIPER */

.input-send {
    background: transparent;
    border: none;
    border-bottom: 1px solid #FF6060;
    border-radius: 0;
}

.form-control:focus {
    background: transparent;
    color: #FFFFFF;
}

.form-check-label {
    color: #FFFFFF;
    font-weight: 300;
}

.form-check-input:checked {
    background-color: #FF6060;
    border-color: #FF6060;
}

/* ACCORDION */

.accordion-button {
    background-color: transparent;
    color: white;
}

.accordion-faq {
    background: transparent;
    color: white;
    border-radius: 0 !important;
    border-left: none;
    border-right: none;
    border-top: 1px solid #FF6060;
    border-bottom: 1px solid #FF6060;
}

.accordion-faq-header .accordion-button:not(.collapsed) {
    background-color: transparent;
    color: white;
}

.accordion-button:not(.collapsed)::after {
    content: "\f00d";
    font-family: "Font Awesome 5 Free";
    color: white;
    font-weight: 600;
    background-image: none;
  }
  
  .accordion-button.collapsed::after {
    content: "\f067";
    color: white;
    font-weight: 600;
    font-family: "Font Awesome 5 Free";
    background-image: none;
  }

/* ACCORDION */

@media only screen and (max-width: 600px) {}