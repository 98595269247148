@import url('../../global.css');

.searchbar-container {
    background-color: #0C0C0C;
    border-bottom-left-radius: 15px;
    width: 100%;
    padding: 16px;
}

.searchbar-container-detail {
    background-color: #0C0C0C;
    border-bottom-left-radius: 15px;
    width: 100%;
    padding: 16px;
    padding-left: 0 !important;
}

.btn-filter span {
    margin-left: 10px;
    font-size: 0.9rem;
}

.avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.line-user {
    margin: 0;
    height: 1px;
    background: white;
    width: 100%;
}

.avatar-name {
    font-size: 0.9rem;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
}

.search-mb {
    background-color: #0C0C0C;
}

.search-icon-mb {
    font-size: 1.3rem;
}

@media only screen and (max-width: 600px) {
}