@import url('../../global.css');

.nameFooter {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    line-height: 2.3rem;
}

.nameFooter:hover {
    color: #FF0000;
}

.container-social {
    width: 80%;
    border-top: 1px solid#C0C0C0;
    border-bottom: 1px solid#C0C0C0;
}

.txt-footer {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {

    .container-social {
        width: 100% !important;
    }
}