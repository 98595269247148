@import url('../../global.css');

.input-contact {
  border: none;
  color: #FFFFFF;
  background: transparent;
  border-bottom: 1px solid #FF6060 !important;
  border-radius: 0;
}

.form-check-input {
  background: transparent;
  border: 1px solid #FF6060 !important;
}