@import url('../../global.css');

.bg-vector-right {
    position: absolute;
    top: 0;
    z-index: -10;
    right: 0;
}

.bg-vector-left {
    position: absolute;
    top: 68%;
    z-index: -10;
    left: 0;
}

.col-container {
    height: 320px;
}

.card-work {
    background: linear-gradient(180deg, #0C0C0C 13%, rgba(169, 9, 9, 0.5) 100%);
    border-radius: 7px;
    padding: 16px;
    height: 100%;
}

.title-work {
    color: white;
    font-weight: 700;
    font-size: 18px;
}

.text-work {
    font-weight: 300;
    color: white;
    font-style: italic;
}

.banner:hover {
    background: linear-gradient(-270deg, #0C0C0C 0%, #DA0037 42.19%, #F1003D 62.5%, #FF4E4E 79.69%, #FF8080 100%);
}

.banner {
    background: linear-gradient(270deg, #0C0C0C 0%, #DA0037 42.19%, #F1003D 62.5%, #FF4E4E 79.69%, #FF8080 100%);
    width: 100%;
    height: 400px;
}

.btn-work {
    border-color: white !important;
    margin: 0 auto;
    margin-top: 20px;
}

@media only screen and (max-width: 600px) {
}