@font-face {
    font-family: 'Aileron';
    src: url('./assets/fonts/aileron/Aileron-Light.otf');
    font-weight: 300;
}

@font-face {
    font-family: 'Aileron';
    src: url('./assets/fonts/aileron/Aileron-Regular.otf');
    font-weight: 400;
}

@font-face {
    font-family: 'Aileron';
    src: url('./assets/fonts/aileron/Aileron-SemiBold.otf');
    font-weight: 500;
}

@font-face {
    font-family: 'Aileron';
    src: url('./assets/fonts/aileron/Aileron-Bold.otf');
    font-weight: 600;
}

@font-face {
    font-family: 'GoodTimes';
    src: url('./assets/fonts/good-times/good.otf');
    font-weight: 400;
}

/* VARIABLES */
:root {
    --primary: #FF6060;
    --red: #FF0000;
    --gray1: #0C0C0C;
    --gray3: #2A2A2A;
}

input {
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none!important;
}
.bg-footer {
    background-color: #FFFFFF;
    font-family: 'GoodTimes';
}

.bg-footer2 {
    background-color: #0C0C0C;
}

.text-gb {
    color: #FFFFFF;
}

.pointer {
    cursor: pointer;
}

.btn-gb {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-weight: 400;
    font-size: 18px;
}

.bg-footer-publi {
    background-color: #848484;
    height: 80px;
    width: 100%;
    opacity: 1;
    border-top: 1px solid #9a9696;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}

@media only screen and (max-width : 1100px){ /* You can edit the max-width value to match what you need */
  .social-btns {
    height: 80px;
  }

  .social-btn-active {
    transition: 2s;
    height: auto !important;
  }
}

/* FILTER */

.css-187mznn-MuiSlider-root {
    color: #FF6060 !important;
}

.popover {
    max-width: 350px !important;
}

.fade.show.popover-card.popover.bs-popover-bottom {
    background: transparent !important;
    border-radius: 20px;
}

.fade.show.popover-filter.popover.bs-popover-bottom {
    border-radius: 20px;
    background: linear-gradient(220deg, rgba(255, 255, 255, 0.8) 0%, rgba(0, 0, 0, 1) 100%);
}

.fade.show.popover-user.popover.bs-popover-bottom {
    border-radius: 20px;
    background: linear-gradient(109.46deg, rgba(0, 0, 0, 0.8) 1.57%, rgba(0, 0, 0, 0.1) 100%);
}

.popover-arrow {
    display: none !important;
}

.popover-header {
    background: transparent;
}

.popover-body {
    background: transparent;
}

/* FILTER */

/* BUTTONS */

.social-btns {
    z-index: 999;
    transform: 90deg;
    border: 1px solid #FF0000;
    background-color: #0C0C0C;
    border-radius: 20px;
    width: 45px;
    position: fixed;
    right: 5%;
    top: 50%;
}

ul {
    padding: 0;
    margin-bottom: 0;
}

li {
    list-style: none;
}

.btn-solid {
    font-family: 'GoodTimes' !important;
    background-color: var(--primary) !important;
    font-weight: 300 !important;
    color: white !important;
    border: 1px solid var(--primary);
}
.btn-solid:hover, .btn-solid:active{
    background: transparent !important;
    background-color: transparent !important;
    border: 1px solid var(--primary);
    color: var(--primary) !important;
}

.click {
    cursor: pointer;
}

.btn-global {
    border-color: var(--primary);
    font-family: 'GoodTimes' !important;
}
.btn-global:hover {
    background: transparent !important;
    background-color: transparent !important;
    color: white !important;
}

.btn-global:active {
    color: var(--primary) !important;
}
button:focus {
    box-shadow: 0 0 5px 0 rgba(255, 0, 0, 0) !important;
}
/* BUTTONS */

body {
    min-height: calc(100vh - 86px);
    box-sizing: border-box;
    background-color: #0C0C0C;
    font-family: 'Aileron';
    overflow-x: hidden;
}

.pagination {
    justify-content: center;
    border-radius: 30px;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.page-item.active .page-link {
    color: #FF6060 !important;
}

.page-link:hover {
    color: #FF6060 !important;
}

.page-link {
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none!important;
    background: transparent !important;
    border: none;
    color: white;
}
