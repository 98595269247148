@import url('../../global.css');
.custom-mt-firts{
    margin-top: 40px;
 }
.custom-mt{
   margin-top: 140px;
}
.container-gif {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
}
.b-green {
    background: radial-gradient(circle, rgba(39, 255, 122, 0.2) 0%, rgba(12, 12, 12, 0) 76%) !important;
}
.b-yellow {
    background: radial-gradient(circle, rgba(255, 241, 39, 0.2) 0%, rgba(12, 12, 12, 0) 76%) !important;
}
.b-blue {
    background: radial-gradient(circle, rgba(56, 159, 255, 0.2) 0%, rgba(12, 12, 12, 0) 76%) !important;
}

@media only screen and (max-width: 600px) {
    .custom-mt-firts{
        margin-top: 0 !important;
     }
    .custom-mt{
        margin-top: 40px;
     }
     .custom-position-left {
        top: 50px !important;
     }
     .custom-position-right {
        top: -50px !important;
     }
     .containter-section1 {
        padding-bottom: 0 !important;
     }
}