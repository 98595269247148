@import url('../../global.css');

/* BACKGROUND */

.container-blog-detail {
    background: url('../../assets/imgs/bg_blog_detail.png') no-repeat;
    background-size: cover;
}
.image-blog-detail {
    border-radius: 24px;
    object-fit: cover;
}

/* BACKGROUND */

.chevron-back {
    margin-right: 10px;
}

.chevron-next {
    margin-left: 10px;
}

.space-between {
    justify-content: space-between;
}

.comments-container {
    background-color: #FFFFFF;
}

.date-comment {
    color: #676767;
    font-weight: 400;
}

.container-response {
    border-left: 1px solid #D9D9D9;
}

.send-comment {
    border: 1px solid #676767;
    border-radius: 12px;
    color: black !important;
}

.response {
    color: #676767;
    text-decoration: underline;
}

.btn-send {
    margin: auto;
    margin-right: 0;
}

.input-alias {
    border-bottom: 1px solid #676767;
}

.input-text-alias {
    color: black !important;
}

.btn-response {
    color: #FF6060;
}

@media only screen and (max-width: 600px) {
    .container-blog-detail {
        background: url('../../assets/imgs/bg_blog_detail_mb.png') no-repeat;
        background-size: cover !important;
    }

    .chevron-back {
        margin-right: 5px;
    }
    
    .chevron-next {
        margin-left: 5px;
    }
}