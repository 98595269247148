@import url('../../global.css');

.investment-container {
    position: relative;
    background-color: #0C0C0C;
    padding: 16px;
    border-radius: 10px;
    font-family: 'Aileron';
}

.container-right-dash-c {
    position: absolute;
    z-index: -10 !important;
    right: 0;
    top: 1000px;
    width: 600px;
    height: 600px;
}

.container-right-dash-d {
    position: absolute;
    z-index: -10 !important;
    right: 0;
    top: 1900px;
    width: 600px;
    height: 600px;
}

.card-investment {
    background: linear-gradient(109.46deg, rgba(201, 201, 201, 0.8) 1.57%, rgba(196, 196, 196, 0.1) 100%);
    backdrop-filter: blur(25px);
    border-radius: 24px;
    border: none;
}

.card-investment .card-body img {
    filter: drop-shadow(8px 8px 15px rgba(0, 0, 0, 0.25));
    border-radius: 24px;
    object-fit: cover;
    height: 100%;
}

.investment-img {
    height: 100%;
}

.card-investment .card-body {
    padding: 0;
}

.chip-investment {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: left;
    top: 16px;
    left: 5px;
    font-size: 0.8rem;
    padding: 8px 12px 8px 32px;
    border-radius: 100px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.3) 100%);
    z-index: 2;
}

@media only screen and (max-width: 600px) {
    .chip-investment {
        transform: rotate(-90deg);
        top: 63%;
        left: -30px;
    }

    .investment-container {
        background-color: transparent !important;
        padding-right: 0 !important;
    }
}

@media (max-height: 660px) {
    .card-investment2 {
        height: 750px;
    }
}