@import url('../../global.css');

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 20vh !important;
    max-height: 928px !important;
    z-index: 100 !important;
    background-color: #0C0C0C;
    border-radius: 0 0 0 20px;
    padding: 50px;
    border-width: 1px;
    border-style: solid;
    border-left: none;
    border-image:
        linear-gradient(to bottom,
            white,
            rgba(0, 0, 0, 0)) 1 100%;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

#sidebar-wrapper {
    min-height: 20vh !important;
    width: 100vw;
    margin-left: -1rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
}

.active-btn {
    width: 180px;
    border-radius: 32px;
    background: #FF6060;
    color: white;
    box-shadow: inset 6px 4px 5px rgba(0, 0, 0, 0.44);
}

.link-side:hover {
    width: 180px;
    border-radius: 32px;
    background: #FF6060;
    color: white;
    box-shadow: inset 6px 4px 5px rgba(0, 0, 0, 0.44);
}

.logout {
    position: absolute;
    bottom: 15px;
}

.icon-m {
    margin-right: 10px !important;
}

.line-menu {
    background: white;
    height: 1px;
    width: 75%;
    position: absolute;
    left: 0;
}

.btn-sidemenu-active {
    width: 190px;
    border-radius: 32px;
    background: #FF6060;
    box-shadow: inset 6px 4px 5px rgba(0, 0, 0, 0.44);
}

.tab {
    position: fixed;
    z-index: 999;
    bottom: 0;
    background-color: #0C0C0C;
    width: 100%;
    padding: 16px;
    border: 1px solid;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-image: linear-gradient(45deg, white, #0C0C0C) 1;
}

.icon-tab {
    font-size: 1.4rem;
}

.active-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FF6060;
    box-shadow: inset 6px 4px 5px rgba(0, 0, 0, 0.44);
    width: 40px;
    height: 40px;
    border-radius: 50%;

}

@media only screen and (max-width: 600px) {}