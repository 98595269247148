@import url('../../global.css');

.grl-mb {
    margin-bottom: 54px;
}

/* BACKGROUND */
.container-trama {
    position: absolute;
    z-index: -10 !important;
    right: 0;
    top: -40px;
    max-width: 1200px;
    max-height: 1200px;
}

.card-work-home {
    background: linear-gradient(180deg, #0C0C0C 13%, rgba(169, 9, 9, 0.5) 100%);
    border-radius: 7px;
    padding: 16px;
    height: 350px;
}

.trama {

    width: 100%;
    height: 100%;
}


.container-center {
    position: absolute;
    z-index: -10 !important;
    top: 8%;
    right: 25%;
    width: 800px;
    height: 800px;
}

.ball-center {
    background: radial-gradient(circle, rgba(255, 0, 0, 0.5) 0%, rgba(12, 12, 12, 0) 25%, rgba(12, 12, 12, 0) 100%);
    width: 100%;
    height: 100%;
}

.container-vector-left {
    position: absolute;
    z-index: -10 !important;
    left: 0px;
    top: 80%;
}

.container-vector-right {
    position: absolute;
    z-index: -10 !important;
    right: 0px;
    bottom: -14%;
}

.container-right {
    position: absolute;
    z-index: -10 !important;
    right: -800px;
    bottom: -300px;
    width: 1200px;
    height: 1200px;
}

.ball-right {
    background: radial-gradient(circle, rgba(255, 0, 0, 0.4) 0%, rgba(12, 12, 12, 0) 60%);
    width: 100%;
    height: 100%;
}

.container-top {
    position: absolute;
    z-index: -10 !important;
    right: 340px;
    top: -240px;
    width: 1000px;
    height: 1000px;
}

.ball-top {
    background: radial-gradient(circle, rgba(255, 0, 0, 0.2) 0%, rgba(12, 12, 12, 0) 60%);
    width: 100%;
    height: 100%;
}

.container-right-b {
    position: absolute;
    z-index: -10 !important;
    right: -900px;
    top: -300px;
    width: 1200px;
    height: 1200px;
}

.container-left-c {
    position: absolute;
    z-index: -10 !important;
    left: -400px;
    top: 220px;
    width: 980px;
    height: 980px;
}

.ball-c {
    filter: blur(2px);
    background: radial-gradient(circle, rgba(255, 8, 8, 0.3) 0%, rgba(12, 12, 12, 0) 76%);
    width: 100%;
    height: 100%;
}

.container-green-left {
    position: absolute;
    z-index: -10 !important;
    top: -300px;
    left: -600px;
    width: 1000px;
    height: 1000px;
}

.container-green-right {
    position: absolute;
    z-index: -10 !important;
    top: -300px;
    right: -600px;
    width: 1000px;
    height: 1000px;
}

.ball-cyan {
    filter: blur(2px);
    background: radial-gradient(circle, rgba(0, 255, 213, 0.2) 0%, rgba(12, 12, 12, 0) 76%);
    width: 100%;
    height: 100%;
}

/* BACKGROUND */

/* TEXTS */
.text-line {
    position: absolute;
    bottom: -16px;
    right: 0;
    width: 400%;
    height: 8px;
    background-color:red;
}
.btn-border-light {
    border-color: white !important;
}

.title {
    font-family: 'GoodTimes';
    font-weight: 400;
    font-size: 28px;
    color: #FFFFFF;
}

.subtitle {
    font-size: 38px;
    font-weight: 600;
    color: #FFFFFF;
    margin-top: 30px;
}

.subtitle-text {
    font-size: 34px;
    font-weight: 600;
    color: var(--primary);
}

/* TEXTS */

/* CARDS */
.card-blur-reounded {
    margin: auto;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: 800px;
    max-width: 500px;
    padding: 80px 0;
    border-radius: 300px;
}

.card-blur-reounded::before {
    content: '';
    position: absolute;
    width: 80%;
    height: 100%;
    border-radius: 300px;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: -1;
}

.animation {
    margin-bottom: 100px;
}

.logotype {
    position: relative;
    bottom: 60px;
}

.card-blur-reounded::after {
    content: '';
    position: absolute;
    bottom: 160px;
    left: 10px;
    width: 280px;
    height: 280px;
    border-radius: 100%;
    background: rgba(255, 0, 0, 0.3);
    filter: blur(80px);
    z-index: -2;
}

.clip-img {
    max-height: 70%;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.triangule {
    width: 500px;
    position: absolute;
    top: 0%;
}

.btn-mb {
    position: absolute;
    bottom: 5%;
    font-size: 1.3rem;
}

.img-card {
    position: absolute;
    bottom: 20%;
    width: 200px;
}

.container-card-white {
    position: relative !important;
    background: transparent !important;
    border: 0;
    height: 750px;
    overflow: hidden;
}

.custom-top {
    padding-top: 160px;
    margin-top: 80px;
}

.relative {
    position: relative;
}

.custom-card-white {
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(100% - 80px);
    width: 100%;
    z-index: -9;
    border-radius: 24px;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    background: linear-gradient(222deg, rgba(255, 255, 255, 0.3) 0%, rgba(0, 0, 0, 0.2) 100%);
}

.size-btn-home {
    font-size: 1rem !important;
}

.size-btn-home:hover {
    border-color: #FF6060 !important;
}

.container-img-card {
    padding: 0 16px;
}

.chip {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: left;
    top: 16px;
    left: 32px;
    padding: 8px 12px 8px 32px;
    border-radius: 100px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.3) 100%);
    z-index: 2;
}


.dot {
    position: absolute;
    left: 8px;
    width: 16px;
    height: 16px;
    border-radius: 100px;
    z-index: 3;
}

.dot-design {
    background-color: green;
}

.dot-subscription {
    background-color: orange;
}

.dot-finish {
    background-color: red;
}

.dot-fonded {
    background-color: blue;
}

.mascara {
    overflow: hidden;
    border-radius: 24px;
}

.mascara img {
    height: 272px;
    object-fit: cover;
    border-radius: 24px !important;
}

.custom-ligth {
    font-weight: 300 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(255, 255, 255, 0.9) !important;
}

.custom-bold {
    font-size: 14pt;
    font-weight: 600 !important;
}

.txt-small {
    font-size: 10pt;
    letter-spacing: 0.5pt;
}

.card-rounded {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    width: 210px;
    height: 210px;
    border-radius: 50%;
    background-color: #2a2a2a;
    border: 10px solid #0000004a;
    margin: 0 auto;
}

.icon-swiper-container {
    height: initial !important;
}

.container-truncate {
    position: relative;

}

/* CARDS */

/* PROGRESS */
.custom-progress {
    height: 12px !important;
    background-color: var(--gray1) !important;
}

.progress-bar {
    background-color: var(--primary) !important;
}

/* PROGRESS */


/* SWIPER */
.swiper {
    overflow: initial !important;
}

.swiper-pagination {
    bottom: -30px !important;
}

.swiper-pagination-bullet {
    border: 1px solid #FFFFFF !important;
    background-color: transparent !important;
}

.swiper-button-prev {
    left: -20px !important;
}
.swiper-button-next {
    top: 46% !important;
    right: -20px !important;
}
.swiper-button-next:after,
.swiper-button-prev:after {
    font-family: none !important;
    color: transparent !important;
    background-repeat: no-repeat !important;
    width: 40px;
    left: 40px;
}

.swiper-button-next:after {
    transform: rotate(180deg);
    background-image: url('../../assets/imgs/arrow.svg');
}

.swiper-button-prev:after {
    background-image: url('../../assets/imgs/arrow.svg');
}

.title-slider {
    margin: 26px 0;
    font-family: 'GoodTimes';
    font-size: 16pt;
}

.custom-pl {
    padding-right: 60px;
}

.card-round-faq {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background-color: #2a2a2a;
    border: 10px solid #0000004a;
    position: relative;
    padding: 10px;
    opacity: 0.5;
    cursor: pointer;
    margin: 0 auto;
}

.active-card {
    opacity: 1;
    transition: 1s;
}

.swiper-home-cards>.swiper-wrapper>.swiper-slide {
    height: 100% !important;
}

.swiper-home-cards>.swiper-wrapper>.swiper-slide>.card-work {
    /* background-color: #00cc55 !important; */
    height: 100% !important;
}


/* SWIPER */
.m-top {
    margin-top: 80px;
}

@media only screen and (max-width: 600px) {

    .text-line {
        display: none !important;
    }
    .logotype {
        bottom: 60px;
        width: 180px;
    }

    .padding-responsive {
        padding-top: 1.5rem;
    }

    .p-none {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .responsive-txt-center {
        text-align: center !important;
    }

    .custom-ma {
        width: 90% !important;
        margin: auto !important;
    }

    .container-vector-left {
        left: initial !important;
        right: -16px !important;
        bottom: -60px;
        transform: rotate(180deg);
    }

    .vector-responsive {
        height: 40% !important;
    }

    /* BALLS */
    .container-trama {
        width: 1200px !important;
        height: 1200px !important;
    }

    .container-right {
        top: 0 !important;
    }

    .container-top {
        display: none !important;
    }

    .container-left-c {
        top: -900px;
        left: -800px !important;
        right: 0 !important;
    }

    .container-right-b {
        top: 100px !important;
    }

    .card-blur-reounded::after {
        display: none !important;
    }

    .container-green-left {
        position: absolute;
        z-index: -10 !important;
        top: 450px;
        left: -150px;
        width: 400px;
        height: 400px;
    }

    .container-green-right {
        position: absolute;
        z-index: -10 !important;
        top: 250px;
        right: -150px;
        width: 400px;
        height: 400px;
    }

    /* BACKGROUND */

    .container-right {
        top: 20%;
    }

    .container-left {
        top: 0;
    }

    .container-trama {
        right: -150px;
    }

    /* BACKGROUND */

    /* CARDS */
    .card-blur-reounded {
        height: 600px;
        max-width: 300px !important;
        border-radius: 300px;
    }

    .triangule {
        width: 350px;
    }

    .txt-right {
        text-align: right !important;
    }

    .btn-detail {
        width: 100% !important;
        font-size: 1rem !important;
    }

    /* CARDS */

    /* SWIPER */
    .m-top {
        margin-top: 0 !important;
    }

    /* .swiper-button-next:after {
        display: none !important;
    }

    .swiper-button-prev:after {
        display: none !important;
    } */

    .swipper-cards-proyects>.swiper-button-next {
        display: none !important;
    }
    .swipper-cards-proyects>.swiper-button-prev {
        display: none !important;
    }
    
    .swiper-home-cards>.swiper-button-next, .swiper-button-prev {
        display: none !important;
    }

    .swipper-gifs>.swiper-button-next, .swiper-button-prev {
        display: flex  !important;
    }

    .swipper-gifs>.swiper-button-prev {
        left: 10px !important;
        top: 70%;
    }
    .swipper-gifs>.swiper-button-next {
        right: 10px !important;
        top: 66% !important;
    }

    .custom-pl {
        padding-right: 0 !important;
    }

    .responsive-pt-64 {
        padding-top: 64px !important;
    }

    .btn-more {
        text-align: center;
        text-decoration: underline;
        color: var(--primary);
        font-style: italic !important;
        cursor: pointer;
    }
}

@media only screen and (max-width: 600px) {
    
}
