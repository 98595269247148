@import url('../../global.css');

.custom-container {
    position: relative;
}
.swipper-login {
    height: 100% !important;
}

.swipper-login>.swiper-wrapper {}

.custom-bg-login {
    background-color: var(--gray3) !important;
}
.swipper-login>.swiper-button-next {
    top: initial !important;
    bottom: 180px;
    right: 0 !important;
}
.swipper-login>.swiper-button-prev {
    top: initial !important;
    bottom: 178px;
    left: 0 !important;
}
.swipper-login>.swiper-button-next:after {
    transform: rotate(360deg);
    background-image: url('../../assets/imgs/arrow_double.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 46px;
    height: 46px;
    border-radius: 100%;
    border: 1px solid white;
}
.swipper-login>.swiper-button-prev:after {
    transform: rotate(180deg);
    background-image: url('../../assets/imgs/arrow_double.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 46px;
    height: 46px;
    border-radius: 100%;
    border: 1px solid white;
}

.swipper-login>.swiper-button-next.swiper-button-disabled {
    opacity: 0 !important;
}
.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
    opacity: 0 !important;
}

.swipper-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

.img-trama {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/imgs/trama_singin.png');
    background-position: bottom left;
    z-index: -1;
    background-repeat: no-repeat;
}
.btn-mb-88 {
    margin: auto 0 88px auto; 
}

.txt-gray {
    color:rgba(255, 255, 255, 0.7) !important;
}
.custom-placeholder::placeholder {
    color:rgba(255, 255, 255, 0.7) !important;
    font-style: italic;
}

.btn-close {
    color: var(--primary) !important;
    font-size: 1.5rem;
    background: transparent !important;
    opacity: 1 !important;
}
.form-check-label{
    color:rgba(255, 255, 255, 0.7) !important;
}

/*  color: rgba(255, 255, 255, 0.9) !important; */

@media only screen and (max-width: 600px) {
}