@import url('../../global.css');

.avatar-detail {
    border-radius: 50%;
    width: 108px;
    height: 108px;
    object-fit: cover;
}

.text-left {
    text-align: left !important;
    font-weight: 300;
}

.btn-card-mb {
    border: 1px solid white;
    border-radius: 30px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.detail-tab.active {
    color: #FF6060;
    text-decoration: underline;
    text-decoration-color: #FF6060;
    text-decoration-thickness: 1px;
    text-underline-offset: 6px;
}

.detail-tab {
    font-size: 0.9rem;
}

.detail-tab:hover {
    color: #FF6060;
}

.separator-detail {
    background: white;
    width: 100%;
    height: 1px;
}

.detail-img {
    border-radius: 12px;
    width: 100%;
    object-fit: cover;
    height: 358px;
}

.detail-card {
    z-index: 999;
    border: 1px solid #D3D3D3;
    padding: 10px;
    border-radius: 24px;
    background: linear-gradient(109.46deg, rgba(0, 0, 0, 1) 1.57%, rgba(0, 0, 0, 1) 100%);
}

.detail-text-card {
    font-weight: 300;
    font-size: 15px;
}

.chip-detail {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: right;
    right: 15px;
    padding: 8px 12px 8px 32px;
    border-radius: 100px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.3) 100%);
    z-index: 2;
}

.list-group-item {
    background-color: transparent !important;
    color: white;
    padding-left: 0;
    border: 1px solid rgba(255, 255, 255, 0.527);
}

.input-investment {
    background-color: transparent;
    color: white;
    text-align: right;
    font-size: 1.2rem;
    border-radius: 0;
    border-bottom: 2px solid #FF6060 !important;
}

.text-investment {
    background-color: transparent;
    border: none;
}

.btn-investment {
    height: 40px;
    border-radius: 30px;
}

.swiper-detail {
    width: 100%;
    height: 400px;
}

.swiper-slide-detail {
    background-position: center;
    background-size: cover;
}

.swiper-slide-detail img {
    height: 100%;
    display: block;
    width: 100%;
}

.swiper-images {
    width: 100%;
    height: 191px;
}

.swiper-slide-images {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 12px;
}

.swiper-slide-images img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}


@media only screen and (max-width: 600px) {
    .detail-card {
        background: linear-gradient(109.46deg, rgba(0, 0, 0, 0.9) 1.57%, rgba(0, 0, 0, 0.9) 100%);
    }

    .nav-mobile {
        flex-wrap: nowrap;
        overflow-y: scroll;
    }

    .nav-mobile-item {
        white-space: nowrap;
        margin-right: 10px;
    }
}

@media (max-height: 660px) {}