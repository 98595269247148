@import url('../../global.css');

.container-center-dash {
    position: absolute;
    z-index: -10 !important;
    right: 0;
    top: 140px;
    width: 1300px;
    height: 980px;
}

.container-left-dash-a {
    position: absolute;
    z-index: -10 !important;
    left: -400px;
    top: 220px;
    width: 1300px;
    height: 980px;
}

.container-left-dash-b {
    position: absolute;
    z-index: -10 !important;
    left: 0;
    top: -130px;
    width: 600px;
    height: 600px;
}

.container-right-dash-a {
    position: absolute;
    z-index: -10 !important;
    right: 0;
    top: -130px;
    width: 600px;
    height: 600px;
}

.container-right-dash-b {
    position: absolute;
    z-index: -10 !important;
    right: -400px;
    top: 220px;
    width: 1300px;
    height: 980px;
}
.line-item {
    color: white !important;
    width: 100%;
    height: 1px;
}
.dot2 {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 100px;
}

.activa {
    background-color: #19B000;
}

.finish-status {
    background-color: #FF0000;
}

.main-chart {
    position: relative;
    padding: 16px;
    background-color: #0C0C0C;
    border-radius: 10px;
}

.item {
    margin-left: 5px;
}
.value {
    font-family: 'Aileron';
    font-weight: 700;
    font-size: 18px;
    color: white;
}
.value-success {
    font-family: 'Aileron';
    font-weight: 700;
    font-size: 18px;
    color: #19B000;
}
.minitext {
    font-family: 'Aileron';
    font-weight: 300;
    font-size: 14px;
    color: white;
}
.item img{
    border-radius: 15px;
    width: 100%;
    object-fit: cover;
}

.small-chart {
    position: relative;
    background-color: #0C0C0C;
    border-radius: 10px;
    height: 150px;
}

.size {
    font-size: 14px;
}


.medium-chart {
    position: relative;
    background-color: #0C0C0C;
    border-radius: 10px;
    height: 300px;
}

.second-chart {
    position: relative;
    background-color: #0C0C0C;
    border-radius: 10px;
    padding: 16px;
}

.my-investment {
    color: white;
    margin-left: 16px;
}

@media only screen and (max-width: 600px) {

}